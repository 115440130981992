import React, { Component } from "react";
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";

class Tambah_user extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations_id: "",
      subdomain: "",
      name: "",
      user_level_id: "",
      isEdit: false,
      headerName: 'Tambah User',
      buttonName: "SUBMIT",
    };
  }

  componentDidMount() {
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_unit;

    const user = JSON.parse(localStorage.getItem("DATA_USER"));
    if (user !== null) {
      this.state.id = user.id;
      this.state.user = user.user_id;
      this.state.name = user.name;
      this.state.user_level_id = user.user_level_id;
      this.state.pass = user.password;
      this.state.isEdit = true;
      this.state.headerName = "Edit User";
      this.state.buttonName = "UPDATE";
    }

    this.setState({ subdomain: params.subdomain })
  }

  createSelectUserLevel() {
    let items = [];
    // let params = JSON.parse(
    //   localStorage.getItem("DATA_PARAMETER")
    // ).prm_user_level;
    let params = [
      { id: 3, name: "Pendataan" },
      { id: 4, name: "Pewawancara" },
      { id: 6, name: "Admin PPDB" },
    ];
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const user = JSON.parse(localStorage.getItem("DATA_USER"));

    fetch(global.variable.LINK_API + "auth/createuser", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success").then((value) => {
            window.location.href = "/listuser";
          });
        } else {
          global.swal("Error", resData.errors[0], "info");
        }
      })
    // .catch((err) => {
    //   if (err) {
    //     if (err.code !== 403) {
    //       global.swal(
    //         "Error",
    //         "Mohon Dicoba Kembali.",
    //         "info"
    //       );
    //     }
    //   } else {
    //     global.swal.stopLoading();
    //     global.swal.close();
    //   }
    // });
  };

  render() {
    const { location } = this.props;
    const { state } = location;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          <a href="/listuser">Daftar User</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          {this.state.headerName}
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>
                                Username
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="Text"
                                name="user"
                                maxLength="100"
                                value={this.state.user}
                                onChange={this.onChange}
                                autoComplete="new-password"
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Password <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="pass"
                                maxLength="100"
                                value={this.state.pass}
                                onChange={this.onChange}
                                autoComplete="new-password"
                                required
                                disabled={this.state.isEdit}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>
                                Nama <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="Text"
                                name="name"
                                maxLength="100"
                                value={this.state.name}
                                onChange={this.onChange}
                                required
                              />

                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                User Level{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                name="user_level_id"
                                maxLength="100"
                                value={this.state.user_level_id}
                                onChange={this.onChange}
                                // disabled
                                required
                              >
                                <option>Pilih User Level</option>
                                {this.createSelectUserLevel()}
                              </select>
                            </div>
                          </div>
                        </div>
                        <MDBBtn color="primary" block type="submit">
                          {this.state.buttonName}
                        </MDBBtn>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Tambah_user;
