import React, { Component } from 'react';
import ReactExport from "react-export-excel";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import { map } from 'lodash';
import LoadingSpinner from '../components/components/LoadingSpinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require('moment');


class Report_pendaftaran_detail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data_student: '',
      is_show: false,
      data: '',
      listMaster: {},
      reportExcel: [],
      report: [],
      isLoading: true,
    }
  }

  getMaster(id = '', table) {
    var array = this.state.listMaster[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getMasterBeforeState(id = 0, table) {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER'))[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getGuardianData(dataArray = []) {
    let result;
    let guardian = JSON.parse(localStorage.getItem('DATA_PARAMETER')).pdf_guardian;
    if (dataArray.length > 0) {
      var array = dataArray;
      const items = []
      map(guardian, (dt, idx) => {
        var arrayfilter = array.filter((data) => { if (parseInt(data.guardian_id) === parseInt(dt.id)) return data });
        var obj = { name: dt.name }
        var arrayResult = arrayfilter.length > 0 ? { ...arrayfilter[0], ...obj } : dt;
        items.push(arrayResult)
      });
      result = { data: items };
    } else {
      result = { data: [] };
    }
    return result;
  }


  handleClick = param => e => {
    e.preventDefault();
    // localStorage.setItem('student_id', param)
    const findIdx = this.state.report.findIndex((i) => i.registration_id === param.trim());
    var dataStudent = this.state.report[findIdx]
    var dataJalur = dataStudent.registration_id + ' - ' + dataStudent.fullname;
    localStorage.setItem("DATA_JALUR", dataJalur);
    localStorage.setItem("STUDENT_DETAIL", JSON.stringify(dataStudent));
    window.open("/detailstudent", '_blank');
  }

  async componentDidMount() {
    let data_report;
    var links = JSON.parse(localStorage.getItem('report_pendaftaran'));
    this.setState({
      isLoading: true,
    })

    await fetch(global.variable.LINK_API + 'report' + links.trim(),
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        // body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          data_report = resData.data;
        } else {
          global.swal("Error", resData.error, "info")
        }

      });

    let master = JSON.parse(localStorage.getItem('DATA_PARAMETER'));
    const data = [];
    data_report.map((item) => {
      const dataStudent = [];
      let items = Object.create({});
      items['Nomor Pendaftaran'] = item.year + '-' + item.registration_type_id + '-' + item.registration_number
      master.prm_ms_student.map((data2) => {
        if (data2.pdf_table) {
          items[data2.name.trim()] = this.getMasterBeforeState(item[data2.field], data2.pdf_table)
        } else {
          if (data2.field === 'hp') {
            items[data2.name.trim()] = item[data2.field] ? item[data2.field].replace("+62", "0") : '';
          } else {
            items[data2.name.trim()] = item[data2.field] ? item[data2.field] : '';
          }
        }
      });
      items['Keadaan Orang Tua'] = this.getMasterBeforeState(item.parent_status_id, 'pdf_parent_status');
      items['Keadaan Pekerjaan Orang Tua'] = this.getMasterBeforeState(item.working_parent_id, 'pdf_working_parents');
      let guardian = JSON.parse(localStorage.getItem('DATA_PARAMETER')).pdf_guardian;
      if (item.guardian.length > 0) {
        var gd = this.getGuardianData(item.guardian);
        const data2 = [];
        map(gd.data, (dt) => {
          let items2 = Object.create({});
          if ('student_id' in dt) {
            items2[`Orang Tua ${dt.name}`] = dt.name;
            items2[`Nama Lengkap ${dt.name}`] = dt.fullname;
            items2[`NIK ${dt.name}`] = dt.nik;
            items2[`Tanggal Lahir ${dt.name}`] = dt.dob;
            items2[`Agama ${dt.name}`] = this.getMasterBeforeState(dt.religion_guardian_id, 'pdf_religion');
            items2[`Status ${dt.name}`] = this.getMasterBeforeState(dt.status_guardian_id, 'pdf_parent_status');
            items2[`Pendidikan ${dt.name}`] = this.getMasterBeforeState(dt.status_guardian_id, 'pdf_education');
            items2[`Pekerjaan ${dt.name}`] = this.getMasterBeforeState(dt.occupation_id, 'pdf_occupation');;
            items2[`Penghasilan ${dt.name}`] = dt.salary;
            items2[`No HP. ${dt.name}`] = dt.hp.replace("+62", "0");
            items2[`Alamat ${dt.name}`] = dt.address;
          } else {
            items2[`Orang Tua ${dt.name}`] = dt.name;
            items2[`Nama Lengkap ${dt.name}`] = '';
            items2[`NIK ${dt.name}`] = '';
            items2[`Tanggal Lahir ${dt.name}`] = '';
            items2[`Agama ${dt.name}`] = '';
            items2[`Status ${dt.name}`] = '';
            items2[`Pendidikan ${dt.name}`] = '';
            items2[`Pekerjaan ${dt.name}`] = '';
            items2[`Penghasilan ${dt.name}`] = '';
            items2[`No HP. ${dt.name}`] = '';
            items2[`Alamat ${dt.name}`] = '';
          }
          data2.push(items2)
        })
        var newObj = Object.assign({}, ...data2);
        items = { ...items, ...newObj }
      } else {
        const data2 = [];
        map(guardian, (dt) => {
          let items2 = Object.create({});
          items2[`Orang Tua ${dt.name}`] = dt.name;
          items2[`Nama Lengkap ${dt.name}`] = '';
          items2[`NIK ${dt.name}`] = '';
          items2[`Tanggal Lahir ${dt.name}`] = '';
          items2[`Agama ${dt.name}`] = '';
          items2[`Status ${dt.name}`] = '';
          items2[`Pendidikan ${dt.name}`] = '';
          items2[`Pekerjaan ${dt.name}`] = '';
          items2[`Penghasilan ${dt.name}`] = '';
          items2[`No HP. ${dt.name}`] = '';
          items2[`Alamat ${dt.name}`] = '';
          data2.push(items2)
        })
        var newObj = Object.assign({}, ...data2);
        items = { ...items, ...newObj }
      }
      items[`Catatan`] = item.note_verification ? item.note_verification : '';
      data.push(items)
    })
    this.setState({
      report: data_report,
      listMaster: master,
      reportExcel: data,
      isLoading: false,
    })
  }

  createExcelSheet(data) {
    let final = [];
    for (var key in data[0]) {
      final.push(<ExcelColumn label={key} value={key} key={key} />);
    }
    return final;
  }


  render() {
    let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Laporan Pendaftaran
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                      {
                        this.state.isLoading ? <LoadingSpinner /> : ""
                      }
                      <ExcelFile element={<MDBBtn color='primary' block style={{ margin: 5, height: 50 }}>Download Data</MDBBtn>}>
                        <ExcelSheet data={this.state.reportExcel} name="Report Pendaftaran">
                          {this.state.reportExcel.length > 0 ? this.createExcelSheet(this.state.reportExcel) : null}
                        </ExcelSheet>
                      </ExcelFile>

                      <MDBTable >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nama Lengkap (Nama dapat diklik)</th>
                            <th>Nomor Pendaftaran</th>
                            <th>Nomor Handphone</th>
                            <th>Status</th>
                            <th>Catatan</th>
                            {/* <th>Pendaftaran</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.report.map((item, index) => {
                            counter++;
                            return (
                              <tr key={index}>
                                <td>{counter}</td>
                                <td style={{ color: 'blue' }} onClick={this.handleClick(item.registration_id)}>{item.fullname}</td>
                                <td>{item.year + '-' + item.registration_type_id + '-' + item.registration_number}</td>
                                <td>{item.hp ? item.hp.replace("+62", "0") : ''}</td>
                                <td>{this.getMaster(item.flow_id, 'pdf_flow')}</td>
                                <td>{item.note_reject}</td>
                                {/* <td>{item.is_offline === 1 ?
                                      <div style={{ color: 'red' }}>Offline</div>
                                      :
                                      <div style={{ color: 'green' }}>Online</div>
                                    }
                                    </td> */}
                              </tr>
                            )
                          })}
                        </tbody>
                      </MDBTable>
                      {/* </div>
                      </div> */}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Report_pendaftaran_detail;